import Trustbox from '@/app/[lang]/[partner]/components/Trustbox';
import { Chevron } from '@/app/components/icons/Chevron';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { Button } from '@/v2Components/Button/Button';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings = getPartnerSettings(partner);
  return (
    <aside className="relative flex flex-col w-full flex-1">
      <div className="flex-1 mb-8 rounded-2xl overflow-hidden relative min-h-[448px]">
        <Image
          src={`${getEnvVar('NEXT_PUBLIC_CDN')}${partnerSettings.imagesPath}${
            partnerSettings.images!.encoreHeroImage
          }`}
          alt=""
          className="w-full rounded-tl-3xl rounded-tr-3xl object-cover"
          aria-hidden={true}
          fill
        />
      </div>
      <section className="z-10">
        <div className="w-full rounded-2xl bg-core-1 text-core-8 flex flex-1 border border-core-5 overflow-hidden">
          <div className="flex-1 px-9 py-11">
            <h2 className="text-heading-5 text-core-8 mb-k-4">{t('pp-cs-header')}</h2>
            <p className="text-body-4-light text-core-8 mb-5 text-pretty max-w-md ">
              {t('pp-cs-copy')}
            </p>
            {partnerSettings.faqsLink && (
              <Button
                implementAs="a"
                href={partnerSettings.faqsLink}
                id="ReturnToFAQs"
                mixpanelButtonText="Return to FAQs"
                variant="tertiary"
                target="_blank"
                icon={{
                  icon: <Chevron />,
                  position: 'right',
                  slideOnHover: true,
                }}
              >
                {t('pp-cs-return-to-faqs-text')}
              </Button>
            )}
          </div>
          <div className="min-w-[33%] max-w-[40%] content-center bg-core-8 px-9 py-11">
            <div className="mx-auto top-[20px] relative max-w-[180px]">
              <Trustbox
                className="w-full max-w-48"
                width="100%"
                height=""
                theme="dark"
              />
            </div>
          </div>
        </div>
      </section>
    </aside>
  );
};

export { Hero };
