'use client';
import { StepTracker } from '@/app/components/StepTracker';
import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { usePrefetch } from '@/app/hooks/usePrefetch';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import {
  scrollToFirstError,
  validateForm,
} from '@/lib/utils/formValidationHelpers';
import { Button } from '@/v2Components/Button/Button';
import { Hero } from '@/v2Components/Hero';
import { PageLayout } from '@/v2Components/PageLayout';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { getJourneyFlowValue } from '@/v2Utils/journey/journeyUtils';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { ApplicationDisclosure } from '../components/ApplicationDisclosure';
import { BusinessDetailsFormContainer } from '../components/BusinessDetailsFormContainer';
import Consent from '../components/Consent';

export default function EncorePageOne() {
  const params: { partner: string } = useParams();
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const router = useRouter();
  const [movingNext, setMovingNext] = useState<boolean>(false);
  const partnerSettings = getPartnerSettings(params.partner);
  const product = searchParams.get('product');
  const [businessEntityType, setBusinessEntityType] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [mid, setMid] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [addressCity, setAddressCity] = useState<string>('');
  const [addressState, setAddressState] = useState<string>('');
  const [addressPostalCode, setAddressPostalCode] = useState<string>('');
  const [applicationComms, setApplicationComms] = useState<boolean | null>(
    null,
  );
  const [showDisclosure, setShowDisclosure] = useState<boolean>(
    !!partnerSettings?.applicationDisclosureV2 && !state?.disclosure,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const valid = validateForm(e.currentTarget, partnerSettings.country);

    if (valid) {
      let data: any = {
        businessType: businessEntityType,
        businessName: companyName,
        merchantID: mid,
        businessAddress: {
          line1: addressLine1,
          line2: addressLine2,
          townCity: addressCity,
          state: addressState,
          postcode: addressPostalCode,
        },
        applicationComms:
          partnerSettings.consentType === 'implicitConsent'
            ? true
            : applicationComms,
      };

      dispatch(actions.updateOffersPayload(data));
      setMovingNext(true);
      router.push(applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL);
    } else {
      scrollToFirstError();
    }
  };

  useEffect(() => {
    dispatch(
      actions.setJourneyFlow({ journeyFlow: getJourneyFlowValue(product) }),
    );
  }, []);

  useEffect(() => {
    setBusinessEntityType(state?.offersPayload?.company?.business_type ?? '');
    setCompanyName(state?.offersPayload?.company?.legal_name ?? '');
    setMid(state?.offersPayload?.application?.merchant_id ?? '');
    setAddressLine1(
      state?.offersPayload?.company?.registered_address?.line1 ?? '',
    );
    setAddressLine2(
      state?.offersPayload?.company?.registered_address?.line2 ?? '',
    );
    setAddressCity(
      state?.offersPayload?.company?.registered_address?.town_city ?? '',
    );
    setAddressState(
      state?.offersPayload?.company?.registered_address?.state ?? '',
    );
    setAddressPostalCode(
      state?.offersPayload?.company?.registered_address?.postcode ?? '',
    );
    setApplicationComms(
      state?.offersPayload?.consents?.application_comms ?? null,
    );
  }, []);

  usePrefetch(applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
  ) => {
    const targetField = e.target || e.detail.target;
    let inputID: string;
    if (targetField.type === 'radio') {
      inputID = targetField.name;
    } else {
      inputID = targetField.id;
    }
    const value = targetField.value;

    switch (inputID) {
      case 'companyName': {
        setCompanyName(value);
        break;
      }
      case 'mid': {
        setMid(value);
        break;
      }
      case 'businessEntityType': {
        setBusinessEntityType(value);
        break;
      }
      case 'addressLine1': {
        setAddressLine1(value);
        break;
      }
      case 'addressLine2': {
        setAddressLine2(value);
        break;
      }
      case 'addressCity': {
        setAddressCity(value);
        break;
      }
      case 'addressState': {
        setAddressState(value);
        break;
      }
      case 'addressPostalCode': {
        setAddressPostalCode(value);
        break;
      }
      case 'explicitMarketingConsent': {
        setApplicationComms(value === 'true');
        break;
      }
    }
  };

  const hideDisclosure = () => {
    dispatch(actions.updateDisclosure(true));
    setShowDisclosure(false);
  };

  useEffect(() => {
    if (!showDisclosure) {
      window.scrollTo(0, 0);
      const leftPane = document.getElementById('leftPane');
      if (leftPane) {
        leftPane.scrollTo(0, 0);
      }
    }
  }, [showDisclosure]);

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_LandingPage" />
      <PageLayout partner={params.partner}>
        <PageLayout.Left
          heading={t('pp-application')}
          partner={params.partner}
          loading={false}
        >
          {showDisclosure ? (
            <ApplicationDisclosure hideDisclosure={hideDisclosure} />
          ) : (
            <form
              className="flex-1 flex flex-col"
              onSubmit={handleSubmit}
              noValidate
            >
              <BusinessDetailsFormContainer
                fieldValues={{
                  companyName,
                  businessEntityType,
                  mid,
                  addressLine1,
                  addressLine2,
                  addressCity,
                  addressState,
                  addressPostalCode,
                }}
                handleChange={handleChange}
              />
              <Consent
                handleChange={handleChange}
                checkedValue={applicationComms}
              />
              <div className="mt-k-10">
                <Button
                  id="businessDetailStep1"
                  mixpanelButtonText={'business detail 1 continue'}
                  type="submit"
                  loading={movingNext}
                  loadingLabel={t('pp-loading-sr-only-message')}
                >
                  {t('pp-continue')}
                </Button>
              </div>
            </form>
          )}
        </PageLayout.Left>

        <PageLayout.Right hiddenOnMobile={true}>
          {showDisclosure ? null : <Hero />}
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
