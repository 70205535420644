interface BasicSelectProps {
  id: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  placeholder: string;
  value?: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { label: string; value: string }[];
}

const BasicSelect = ({
  id,
  required,
  disabled,
  errorMessage,
  placeholder,
  value,
  handleChange,
  options,
}: BasicSelectProps) => {
  return (
    <div className="relative flex-grow">
      <select
        className={`peer h-[42px] text-core-8 w-full border group-[.loading]/main:text-core-3 group-[.loading]/main:bg-core-1 group-[.loading]/main:border-core-3 group-[.loading]/main:transition-all group-[.loading]/main:duration-200 group-[.loading]/main:!placeholder-core-3 group-[.loading]/main:select-none border-core-5 data-[error=true]:border-error-1 data-[error=true]:bg-error-2/50 data-[error=true]:outline-error-1 leading-10 px-4 placeholder-core-7 rounded ${
          disabled ? 'bg-core-2 border-core-5' : 'bg-core-1'
        }
          `}
        id={id}
        disabled={disabled}
        required={required}
        data-lpignore={true}
        onChange={handleChange}
        defaultValue={value}
      >
        {placeholder && (
          <option value="" className="text-core-5">
            {placeholder}
          </option>
        )}
        {options &&
          Array.isArray(options) &&
          options.map((option) => {
            return (
              <option
                key={option.value}
                value={option.value}
                className="text-core-8"
              >
                {option.label || option.value}
              </option>
            );
          })}
      </select>
      <div className="hidden peer-data-[error=true]:block text-error-1 text-body-4-light pt-1 pr-1 w-full absolute leading-5">
        {errorMessage}
      </div>
    </div>
  );
};

export { BasicSelect };
