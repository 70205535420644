import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { EventTypes, SLIs, useLogEvent } from '@/app/hooks/useLogEvent';
import { useUpdateLead } from '@/app/hooks/useUpdateLead';
import { useParams, useSearchParams } from 'next/navigation';
import { Dispatch, SetStateAction } from 'react';
import { useCheckMIDExists } from '../../../hooks/useCheckMIDExists';
import { useEbaySeller } from '../../../hooks/useEbaySeller';

const useFetchAndProcessPartnerData = (
  setMIDExists: Dispatch<SetStateAction<boolean>>,
) => {
  const params: { partner: string } = useParams();
  const updateLead = useUpdateLead(params.partner);
  const searchParams = useSearchParams();
  const { dispatch } = useGlobalStore();
  const featureFlags = useFeatureFlags();
  const logSli = useLogEvent(EventTypes.SLI);

  const SELLER_KEY = 'external_id';
  const externalId = searchParams.get(SELLER_KEY) || '';

  const {
    isLoading,
    data,
    isError,
    notFound: sellerNotFound,
  } = useEbaySeller((data) => {
    dispatch(actions.updateOffersPayload(data?.mappedData));
    dispatch(actions.updateOriginalData(data?.originalData));

    if (featureFlags['enable-lead-service']) {
      updateLead();
    }

    if (featureFlags['validate-partner-data']) {
      let missingData = false;
      const providedData = data?.originalData?.applicationData;
      const address = providedData?.address;

      if (data?.originalData && typeof address === 'object') {
        const hasState =
          Object(address).hasOwnProperty('stateOrProvince') &&
          !!address.stateOrProvince;
        const hasCity =
          Object(address).hasOwnProperty('city') && !!address.city;
        const hasZipCode =
          Object(address).hasOwnProperty('postalCode') && !!address.postalCode;
        const hasAddressLine1 =
          Object(address).hasOwnProperty('addressLine1') &&
          !!address.addressLine1;
        const hasEmail = !!providedData.emailAddress;
        const hasPhone = !!providedData.phoneNumber;
        if (
          !hasState ||
          !hasZipCode ||
          !hasCity ||
          !hasAddressLine1 ||
          !hasEmail ||
          !hasPhone
        ) {
          missingData = true;
        }
      } else if (data?.originalData) {
        missingData = true;
      }

      if (missingData) {
        logSli(SLIs.MERCHANT_WITH_MISSING_PARTNER_DATA, 1);
      }
    }
  });

  const { isLoading: MIDCheckLoading, isError: MIDCheckError } =
    useCheckMIDExists(params.partner, externalId, (MIDCheckData) => {
      setMIDExists(MIDCheckData);
    });

  return {
    isLoading,
    data,
    isError,
    sellerNotFound,
    MIDCheckLoading,
    MIDCheckError,
  };
};

export { useFetchAndProcessPartnerData };
