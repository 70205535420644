'use client';
import { StepTracker } from '@/app/components/StepTracker';
import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { usePrefetch } from '@/app/hooks/usePrefetch';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { Button } from '@/v2Components/Button/Button';
import { Hero } from '@/v2Components/Hero';
import { PageLayout } from '@/v2Components/PageLayout';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { getJourneyFlowValue } from '@/v2Utils/journey/journeyUtils';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { GenericErrorModal } from '../../components/GenericErrorModal';
import InitialModal from '../../components/InitialModal/InitialModal';
import { BusinessDetailsFormContainer } from '../components/BusinessDetailsFormContainer';
import { useFetchAndProcessPartnerData } from '../hooks/useFetchAndProcessPartnerData';
import { genericErrorModalText } from '../utils/genericErrorModalText';
import Consent from '../components/Consent';

export default function PrepopPageOne() {
  const params: { partner: string } = useParams();
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const router = useRouter();
  const featureFlags = useFeatureFlags();
  const [movingNext, setMovingNext] = useState<boolean>(false);
  const [domLoading, setDomLoading] = useState<boolean>(true);
  const [MIDExists, setMIDExists] = useState<boolean>(false);
  const [closeInitialModal, setCloseInitialModal] = useState<boolean>(false);
  const enableMIDCheck = featureFlags['enable-mid-check'];
  const partnerSettings = getPartnerSettings(params.partner);
  const SELLER_KEY = 'external_id';
  const externalId = searchParams.get(SELLER_KEY) || '';
  const product = searchParams.get('product');

  const handleCloseInitialModal = () => {
    dispatch(
      actions.updateOffersPayload({
        applicationComms: true,
      }),
    );
    setCloseInitialModal(true);
  };

  const {
    isLoading,
    data,
    isError,
    sellerNotFound,
    MIDCheckLoading,
    MIDCheckError,
  } = useFetchAndProcessPartnerData(setMIDExists);

  const nextPage = () => {
    setMovingNext(true);
    router.push(applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL);
  };

  useEffect(() => {
    dispatch(
      actions.setJourneyFlow({ journeyFlow: getJourneyFlowValue(product) }),
    );
  }, []);

  useEffect(() => {
    if (domLoading) {
      setDomLoading(false);
    }
  }, [state?.offersPayload]);

  const isInitialModalOpen = () => {
    const modalNotBlockedByMIDCheck = enableMIDCheck
      ? !MIDCheckError && MIDExists
      : true;

    return (
      !!externalId &&
      !isError &&
      modalNotBlockedByMIDCheck &&
      !closeInitialModal &&
      !sellerNotFound
    );
  };

  const isGenericErrorModalOpen = () => {
    const showMIDCheckError =
      (enableMIDCheck ? MIDCheckError : false) ||
      (enableMIDCheck ? !MIDExists && !MIDCheckLoading && !isLoading : false);

    return isError || showMIDCheckError || !externalId || sellerNotFound;
  };

  const { header: errorHeader, text: errorText } = genericErrorModalText({
    flags: {
      externalId,
      sellerNotFound,
      enableMIDCheck,
      MIDExists,
      isError,
    },
    t,
    partnerSettings,
  });

  const pageIsLoading = isLoading || (enableMIDCheck ? MIDCheckLoading : false);

  usePrefetch(applicationRoutePaths.BUSINESS_DETAILS_PART_TWO_URL);

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_LandingPage" />
      <PageLayout partner={params.partner}>
        <InitialModal
          open={isInitialModalOpen()}
          isLoading={pageIsLoading || domLoading}
          setCloseInitialModal={handleCloseInitialModal}
        />

        <GenericErrorModal
          open={isGenericErrorModalOpen()}
          header={errorHeader}
          text={errorText}
          showButton={true}
          buttonText={t('pp-generic-return-text')}
          buttonProps={{
            implementAs: 'a',
            mixpanelButtonText: 'unknown seller id return button',
            id: 'returnToPartner',
            href: partnerSettings.profileReturnLink,
          }}
        />

        <PageLayout.Left
          heading={t('pp-application')}
          partner={params.partner}
          loading={pageIsLoading}
        >
          <BusinessDetailsFormContainer
            fieldValues={{
              companyName: data?.mappedData?.businessName || '',
              businessEntityType: data?.mappedData?.businessType || '',
              mid: externalId,
              addressLine1: data?.mappedData?.businessAddress?.line1 || '',
              addressLine2: data?.mappedData?.businessAddress?.line2 || '',
              addressCity: data?.mappedData?.businessAddress?.townCity || '',
              addressState: data?.mappedData?.businessAddress?.state || '',
              addressPostalCode:
                data?.mappedData?.businessAddress?.postcode || '',
            }}
            sellerPrepopData={data}
            allowBusinessNameSelect={state?.allowBusinessNameSelect}
          />
          <Consent />
          <div className="mt-k-10">
            <Button
              type="button"
              onClick={nextPage}
              mixpanelButtonText={'business detail 1 continue'}
              id="businessDetailStep1"
              loading={movingNext || pageIsLoading}
              loadingLabel={t('pp-loading-sr-only-message')}
            >
              {t('pp-continue')}
            </Button>
          </div>
        </PageLayout.Left>

        <PageLayout.Right hiddenOnMobile={true}>
          <Hero />
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
