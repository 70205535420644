import { Modal } from '@/app/components/Modal/Modal';
import { ParsedText } from '@/app/components/ParsedText';
import { Button } from '@/v2Components/Button/Button';
import './genericErrorModalStyles.css';

const GenericErrorModal = ({
  open,
  header,
  text,
  showButton = false,
  buttonProps,
  buttonText,
}: {
  open: boolean;
  header: string;
  text: string;
  showButton?: boolean;
  buttonText?: string;
  buttonProps?: any;
}) => {
  const cleanUpOnClose = () => {
    console.log('cleaning up');
    document.body.classList.remove('ReactModal__Body--open');
    document.getElementById('appRoot')?.removeAttribute('aria-hidden');
  };

  return (
    <Modal
      isOpen={open}
      className="genericErrorModal"
      overlayClassName="genericErrorModalOverlay"
      contentLabel="Error"
      onAfterClose={cleanUpOnClose}
    >
      <div
        className={`font-gothic max-w-3xl mx-auto py-6 lmobile:pt-10 flex-1 flex flex-col top-0 group `}
      >
        <div className="flex-1 overflow-auto px-4 mobile:px-6 lmobile:px-10">
          <h2 className={`mb-2 mobile:mb-6 text-header-5 text-core-8`}>
            {header}
          </h2>
          <p className="mb-4 mobile:mb-6 text-core-8 text-body-4-light">
            <span className="">
              <ParsedText htmlString={text} />
            </span>
          </p>

          {showButton && (
            <div className="w-full text-center mb-4">
              <Button {...buttonProps}>{buttonText}</Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { GenericErrorModal };
