'use client';

import { Input } from '@/app/components/Input';
import { Address } from '@/v2Components/Address';
import { Label } from '@/v2Components/Label';
import { PageIntro } from '@/v2Components/PageIntro/PageIntro';
import { PrepopApplicationFastTrackNotice } from '@/v2Components/PrepopApplicationFastTrack';
import { FlexibleSelect } from '../../components/FlexibleSelect';

type BusinessDetailsFormProps = {
  fieldValues: {
    companyName: string;
    businessEntityType: string;
    mid?: string;
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressState: string;
    addressPostalCode: string;
  };
  sellerPrepopData?: any;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  disabled: boolean;
  businessTypeOptions: { label: string; value: string }[];
  stateOptions: { label: string; value: string }[];
  showCompanyName: boolean;
  showBusinessEntity: boolean;
  partnerSettings: any;
  t: (key: string) => string;
};

export const BusinessDetailsForm = ({
  fieldValues,
  sellerPrepopData,
  handleChange = () => {},
  disabled,
  businessTypeOptions,
  stateOptions,
  showCompanyName,
  showBusinessEntity,
  partnerSettings,
  t,
}: BusinessDetailsFormProps) => {
  return (
    <>
      <div
        className={`border ${
          sellerPrepopData?.mappedData?.businessName ? 'border-1' : 'border-0'
        } border-t-0 border-l-0 border-r-0 border-core-4  mb-4`}
      >
        <PageIntro title={t('pp-bd-header')} text={t('pp-bd-intro-copy')} />

        {partnerSettings.prepopulatedJourney && (
          <PrepopApplicationFastTrackNotice
            myWorldLink={partnerSettings.profileReturnLink!}
          />
        )}

        {showCompanyName && (
          <>
            <Label htmlFor="companyName">{t('pp-company-name')}</Label>
            <Input
              type="text"
              id="companyName"
              value={fieldValues.companyName}
              onChange={handleChange}
              required
              readonly={disabled}
              errorMessage={t('pp-company-name-error-message')}
            />
          </>
        )}

        {showBusinessEntity && (
          <>
            <Label id="businessEntityTypeLabel" htmlFor="businessEntityType">
              {t('pp-business-entity')}
            </Label>
            <FlexibleSelect
              id="businessEntityType"
              labelledById="businessEntityTypeLabel"
              placeholder={t('pp-dropdown-default-placeholder')}
              handleChange={handleChange}
              options={businessTypeOptions}
              value={fieldValues.businessEntityType}
              required
              key={fieldValues.businessEntityType}
              disabled={disabled}
              errorMessage={t('pp-business-entity-error')}
            />
          </>
        )}

        {partnerSettings.encore && (
          <>
            <Label htmlFor="mid">{t('business-details-mid-label')}</Label>
            <Input
              type="text"
              id="mid"
              value={fieldValues.mid}
              onChange={handleChange}
              errorMessage={t('business-details-mid-error-message')}
              required
              readonly={disabled}
            />
          </>
        )}
      </div>
      <Address
        legend="pp-registered-address-fieldset"
        fields={{
          line1: {
            id: 'addressLine1',
            label: 'pp-address-line-one',
            value: fieldValues.addressLine1,
          },
          line2: {
            id: 'addressLine2',
            label: 'pp-address-line-two',
            value: fieldValues.addressLine2,
          },
          city: {
            id: 'addressCity',
            label: 'pp-city',
            value: fieldValues.addressCity,
          },
          state: {
            id: 'addressState',
            label: 'pp-state',
            stateOptions: stateOptions,
            value: fieldValues.addressState,
          },
          postcode: {
            id: 'addressPostalCode',
            label: 'pp-zipcode',
            value: fieldValues.addressPostalCode,
          },
        }}
        handleChange={handleChange}
        readonly={disabled}
      />
    </>
  );
};
