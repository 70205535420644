import { Modal } from '@/app/components/Modal/Modal';
import { ParsedText } from '@/app/components/ParsedText';
import { BriefcaseIcon } from '@/app/components/icons/Briefcase';
import { ExternalLinkIcon } from '@/app/components/icons/ExternalLink';
import { PersonalDetailsIcon } from '@/app/components/icons/PersonalDetails';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { Button } from '@/v2Components/Button/Button';
import Image from 'next/image';
import './InitialModalCSS.css';

const InitialModal = ({
  open,
  isLoading,
  setCloseInitialModal,
}: {
  open: boolean;
  isLoading: boolean;
  setCloseInitialModal: () => void;
}) => {
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const partner = usePartner();
  const partnerSettings = getPartnerSettings(partner);

  const skeletonCSS =
    'group-[.loading]:animate-pulse group-[.loading]:!text-core-4 group-[.loading]:!bg-core-4 group-[.loading]:pointer-events-none group-[.loading]:rounded-lg';

  const acceptAndContinue = () => {
    setCloseInitialModal();
  };

  const cleanUpOnClose = () => {
    document.body.classList.remove('ReactModal__Body--open');
    document.getElementById('appRoot')?.removeAttribute('aria-hidden');
  };

  return (
    <Modal
      isOpen={open}
      className="introModal"
      overlayClassName="introOverlay"
      contentLabel="Contracts"
      onRequestClose={acceptAndContinue}
      onAfterClose={cleanUpOnClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div
        className={`font-gothic max-w-3xl mx-auto pt-6 lmobile:pt-10 flex-1 flex flex-col top-0 group ${
          isLoading ? 'loading' : ''
        }`}
      >
        <div className="px-4 mobile:px-6 lmobile:px-10">
          <Image
            src={`${getEnvVar('NEXT_PUBLIC_CDN')}${partnerSettings.imagesPath}${
              partnerSettings.images!.logo
            }`}
            alt={`${partnerSettings.displayName} and Liberis`}
            width={185}
            height={25}
            className="w-[125px] mobile:w-[140px] mb-6 "
          />
        </div>
        <div className="flex-1 overflow-auto px-4 mobile:px-6 lmobile:px-10">
          <h2 className={`mb-4 mobile:mb-6 text-header-5 text-core-8 ${skeletonCSS}`}>
            {t('pp-bd1-modal-header')}
          </h2>
          <p className="mb-4 mobile:mb-6 ">
            <span className={`text-body-4 text-core-8 ${skeletonCSS}`}>
              {t('pp-bd1-seller-account-label')}
            </span>
            <span className={`block text-body-4 mb-4 text-core-8 ${skeletonCSS}`}>
              <a href={partnerSettings.profileReturnLink} target="_blank">
                {state?.offersPayload?.company?.legal_name
                  ? state.offersPayload.company.legal_name
                  : t('pp-bd1-generic-profile-link-text')}
                <span className="h-4 w-4 inline-block top-[2px] ml-2 relative">
                  <ExternalLinkIcon />
                </span>
              </a>
            </span>
            <span className={`block text-body-4 text-core-8  ${skeletonCSS}`}>
              {t('pp-bd1-share-with-liberis-copy')}
            </span>
          </p>
          <ul className="mb-4 mobile:mb-8">
            <li className="mb-4 mobile:mb-8">
              <span
                className={`inline-block h-5 w-5 mr-5 relative top-[3px] ${skeletonCSS}`}
              >
                {!isLoading && <BriefcaseIcon />}
              </span>
              <span className={`mb-4 text-body-4 text-core-8  ${skeletonCSS}`}>
                {t('pp-bd1-business-details-label')}
              </span>
              <div
                className={`block text-core-8 text-body-4-light ml-10 text-pretty mt-2 ${skeletonCSS}`}
              >
                {t('pp-bd1-business-details-data-list')}
              </div>
            </li>
            <li className="">
              <span
                className={`inline-block h-5 w-5 mr-5 relative top-[2px] ${skeletonCSS}`}
              >
                {!isLoading && <PersonalDetailsIcon />}
              </span>
              <span className={`text-body-4 text-core-8 ${skeletonCSS}`}>
                {t('pp-bd1-presonal-details-label')}
              </span>
              <div
                className={`block text-core-8 text-body-4-light ml-10 text-pretty mt-2 ${skeletonCSS}`}
              >
                {t('pp-bd1-personal-details-data-list')}
              </div>
            </li>
          </ul>

          <div className={`w-full text-center`}>
            <Button
              onClick={acceptAndContinue}
              type="button"
              id="landingPageModalContinueButton"
              mixpanelButtonText="Landing page modal clicked"
              loading={isLoading}
              loadingLabel={t('pp-loading-sr-only-message')}
              disabled={isLoading}
            >
              {t('pp-bd1-modal-continue-button-label')}
            </Button>
          </div>
          <div className={`w-full text-center mb-4 my-4`}>
            <Button
              href={partnerSettings.profileReturnLink}
              id="landingPageModalUpdateButton"
              mixpanelButtonText="Update information modal button"
              implementAs="a"
              variant="tertiary"
              disabled={isLoading}
              target="_blank"
            >
              {t('pp-bd1-modal-update-info-button-label')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InitialModal;
