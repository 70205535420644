import { TFunction } from 'i18next';

const genericErrorModalText = ({
  flags,
  t,
  partnerSettings,
}: {
  flags: {
    externalId: string;
    sellerNotFound: boolean;
    enableMIDCheck: boolean;
    MIDExists: boolean;
    isError: boolean;
  };
  t: TFunction<string, undefined>;
  partnerSettings: any;
}) => {
  if (!flags.externalId) {
    return {
      header: t('pp-no-external-id-header'),
      text: t('pp-no-external-id-text'),
    };
  }
  if (flags.sellerNotFound) {
    return {
      header: t('pp-external-id-not-recognised-header'),
      text: t('pp-external-id-not-recognised-text'),
    };
  }
  if (flags.enableMIDCheck && !flags.MIDExists && !flags.isError) {
    return {
      header: t('pp-mid-not-found-in-merchant-profile-header'),
      text: t('pp-mid-not-found-in-merchant-profile-text').replace(
        '{contactPhoneNumber}',
        partnerSettings?.contactPhoneNumber ?? '',
      ),
    };
  }
  return {
    header: t('pp-unable-to-connect-to-seller-api-header'),
    text: t('pp-unable-to-connect-to-seller-api-text').replace(
      '{contactPhoneNumber}',
      partnerSettings?.contactPhoneNumber ?? '',
    ),
  };
};

export { genericErrorModalText };
