import { useRouter } from 'next/navigation';
import { useMemo } from 'react';

const usePrefetch = (url: string | string[]) => {
  const router = useRouter();

  useMemo(() => {
    try {
      if (Array.isArray(url)) {
        url.forEach((u) => router.prefetch(u));
      } else {
        router.prefetch(url);
      }
    } catch (error) {
      console.log('Error prefetching URL:', error);
    }
  }, [url]);
};

export { usePrefetch };
