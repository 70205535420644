import { validateElement } from '@/lib/utils/formValidationHelpers';
import classname from 'classnames';

export interface RadioInputProps {
  disabled?: boolean;
  checked?: boolean;
  id: string;
  label: string;
  name?: string;
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
  error?: boolean;
  required?: boolean;
  errorMessage?: string;
}

const RadioInput = ({
  checked,
  disabled,
  id,
  label,
  name,
  handleChange,
  value,
  error,
  required = false,
  errorMessage,
}: RadioInputProps) => {
  const booleanAttributes: { defaultChecked?: boolean } = {};

  const validateAndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    validateElement(e.target);
  };

  return (
    <div
      className={classname('table group', {
        'pointer-events-none': disabled,
      })}
    >
      <div className="flex relative">
        <div>
          <input
            type="radio"
            id={id}
            name={name}
            disabled={disabled}
            className={classname(
              'peer relative appearance-none w-k-7 h-k-7 border border-core-8 rounded-full disabled:border-core-6',
              'focus:outline-none focus:ring-core-8/20 focus:ring-8 focus:bg-core-8/20',
              'group-hover:ring-core-8/10 group-hover:ring-8 group-hover:ring-offset-0 group-hover:bg-core-8/10',
              { 'border-error-1': error },
            )}
            onChange={(e) => {
              validateAndChange(e);
            }}
            value={value}
            required={required}
            checked={checked}
          />
          <div
            className={classname(
              'text-core-1',
              'pointer-events-none',
              'absolute top-0',
              'w-[14px] h-[14px]',
              'm-[3px]',
              'peer-checked:peer-disabled:bg-gray-400',
              'rounded-full',
              'peer-checked:bg-core-8',
              'group-hover:peer-checked:bg-core-7',
              'peer-focus:peer-checked:bg-core-7',
              { 'peer-checked:bg-core-6': disabled },
              { 'peer-checked:bg-error-1': error },
            )}
          />
          {errorMessage && (
            <div className="hidden peer-data-[error=true]:block text-body-4-light text-error-1 absolute">
              {errorMessage}
            </div>
          )}
        </div>
        <label
          htmlFor={id}
          className={classname(
            'relative ml-k-6 text-start hover:cursor-pointer text-body-3',
            {
              'text-core-6': disabled,
            },
          )}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export { RadioInput };
