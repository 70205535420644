import { MappedSellerData } from '@/app/api/[partner]/ebaySeller/types';
import {
  ApiCalls,
  EventTypes,
  UserEvents,
  useLogEvent,
} from '@/app/hooks/useLogEvent';
import { api } from '@/app/network/api';
import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useParams, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export const fetchEbaySellerDetails = async ({
  partner,
  externalId,
  traceId,
  spanId,
  logAPICall,
  logUserEvent,
  successCallback,
  setNotFound,
  logError,
}: {
  partner: string;
  externalId: string;
  traceId: string;
  spanId: string;
  logUserEvent: (eventName: UserEvents, value?: string) => void;
  logAPICall: (requestName: string, traceId: string, spanId: string) => void;
  successCallback: (data: any) => void;
  setNotFound: (notFound: boolean) => void;
  logError: (
    error: Error,
    traceId?: string,
    spanId?: string,
    metadata?: object,
  ) => void;
}) => {
  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
  const partnerURLHost = host_url?.replace('{partner}', partner);

  if (!externalId) {
    logUserEvent(UserEvents.MISSING_EXTERNAL_ID);
    return {};
  }

  try {
    const response = await api.get(`/api/${partner}/ebaySeller/${externalId}`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    const offerResponse = response.data;

    if (!offerResponse) {
      logUserEvent(UserEvents.ACTIVE_CUSTOMER_NO_MERCHANT_DATA);
      return null;
    }
    successCallback(offerResponse);
    return offerResponse;
  } catch (error) {
    if (isAxiosError(error)) {
      const { response, request } = error;
      if (response) {
        switch (response.status) {
          case 404: {
            setNotFound(true);
            logUserEvent(UserEvents.NO_PREPOP_DATA);
            return {};
          }
          default: {
            logError(
              new Error('Unexpected ebay response', {
                cause: error,
              }),
            );
            break;
          }
        }
      } else if (request) {
        if (request?.statusText === 'abort') {
          return null;
        } else {
          logError(new Error(request.statusText));
        }
        return null;
      } else {
        logError(new Error(error.message));
        return null;
      }
    } else if (error instanceof Error) {
      logError(error);
      return null;
    } else {
      logError(new Error('Unknown error'));
      return null;
    }
  }
};

export const useEbaySeller = (successCallback: (data: any) => void) => {
  const [notFound, setNotFound] = useState(false);
  const SELLER_KEY = 'external_id';
  const params: { partner: string } = useParams();
  const searchParams = useSearchParams();
  const externalId = searchParams.get(SELLER_KEY) || '';

  const logAPICall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const traceId = typeof window !== 'undefined' ? generateUniqueId() : '';
  const spanId = typeof window !== 'undefined' ? generateUniqueId() : '';

  const fetchEbaySellerDetailsParams: {
    partner: string;
    traceId: string;
    spanId: string;
    logAPICall: (requestName: string, traceId: string, spanId: string) => void;
    logUserEvent: (eventName: UserEvents, value?: string) => void;
    externalId: string;
    successCallback: (data: any) => void;
    setNotFound: (notFound: boolean) => void;
    logError: (
      error: Error,
      traceId?: string,
      spanId?: string,
      metadata?: object,
    ) => void;
  } = {
    partner: params.partner,
    traceId,
    spanId,
    logUserEvent,
    logAPICall,
    externalId,
    successCallback: successCallback,
    setNotFound,
    logError,
  };

  useEffect(() => {
    if (notFound) {
      logUserEvent(UserEvents.EXTERNAL_ID_NOT_RECOGNISED);
    } else {
      logUserEvent(UserEvents.EXTERNAL_ID_RECOGNISED);
    }
  }, [notFound]);

  const query = useQuery({
    queryKey: ['Seller details'],
    queryFn: () => fetchEbaySellerDetails(fetchEbaySellerDetailsParams),
    retry: (failureCount) => {
      if (failureCount < 3) {
        return true;
      } else {
        logError(new Error(`Seller API response not ok`), traceId, spanId);
        return false;
      }
    },
  });

  return { ...query, notFound };
};
