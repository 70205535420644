import { detectMob } from '@/lib/utils/detectMobile';
import { BasicSelect } from './BasicSelect';
import { Select } from './Select';

interface FlexibleSelectProps {
  id: string;
  labelledById: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  placeholder: string;
  value?: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { label: string; value: string }[];
  narrow?: boolean;
}

const FlexibleSelect = ({
  id,
  labelledById,
  required,
  disabled,
  errorMessage,
  placeholder,
  value,
  handleChange,
  options,
  narrow = false,
}: FlexibleSelectProps) => {
  const mobile = detectMob();

  return (
    <div
      className={`${
        narrow ? 'mb-0' : 'mb-8'
      } after:clear-both after:content-[''] after:block w-full group-[.loading]/main:animate-pulse group-[.loading]/main:border-core-3 group-[.loading]/main:transition-all group-[.loading]/main:duration-200 group-[.loading]/main:select-none text-body-3-light`}
    >
      {mobile ? (
        <BasicSelect
          id={id}
          required={required}
          disabled={disabled}
          errorMessage={errorMessage}
          placeholder={placeholder}
          handleChange={handleChange}
          options={options}
          value={value}
        />
      ) : (
        <Select
          id={id}
          labelledById={labelledById}
          required={required}
          disabled={disabled}
          errorMessage={errorMessage}
          placeholder={placeholder}
          handleChange={handleChange}
          options={options}
          value={value}
        />
      )}
    </div>
  );
};

export { FlexibleSelect };
