'use client';

import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { translateDropdownLabels } from '@/lib/utils/translateDropdownLabels';
import { useParams } from 'next/navigation';
import { BusinessDetailsForm } from './BusinessDetailsForm';

type BusinessDetailsContainerProps = {
  fieldValues: {
    companyName: string;
    businessEntityType: string;
    mid?: string;
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressState: string;
    addressPostalCode: string;
  };
  sellerPrepopData?: any;
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  allowBusinessNameSelect?: boolean;
};

export const BusinessDetailsFormContainer = ({
  fieldValues,
  sellerPrepopData,
  handleChange = () => {},
  allowBusinessNameSelect,
}: BusinessDetailsContainerProps) => {
  const { t } = useTranslation();
  const params: { partner: string } = useParams();
  const partnerSettings = getPartnerSettings(params.partner);

  const disableFields = !!partnerSettings.prepopulatedJourney;
  const businessTypeOptions = translateDropdownLabels(
    partnerSettings.businessTypeOptions!,
    t,
  );
  const stateOptions = translateDropdownLabels(
    partnerSettings.shortStateOptions || [],
    t,
  );

  const showCompanyName =
    (partnerSettings.prepopulatedJourney &&
      sellerPrepopData?.mappedData?.businessName &&
      allowBusinessNameSelect !== false) ||
    partnerSettings.encore;

  const showBusinessEntity =
    (partnerSettings.prepopulatedJourney &&
      sellerPrepopData?.mappedData?.businessType !== 'Unknown') ||
    partnerSettings.encore ||
    true;

  return (
    <BusinessDetailsForm
      fieldValues={fieldValues}
      sellerPrepopData={sellerPrepopData}
      handleChange={handleChange}
      disabled={disableFields}
      businessTypeOptions={businessTypeOptions}
      stateOptions={stateOptions}
      showCompanyName={showCompanyName}
      showBusinessEntity={showBusinessEntity}
      partnerSettings={partnerSettings}
      t={t}
    />
  );
};
