'use client';

import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { useParams } from 'next/navigation';
import EncorePageOne from './business-details-one/pages/EncorePageOne';
import PrepopPageOne from './business-details-one/pages/PrepopPageOne';

export default function BusinessDetailsOnePageSelector() {
  const params: { partner: string } = useParams();
  const partnerSettings = getPartnerSettings(params.partner);

  return partnerSettings.encore ? <EncorePageOne /> : <PrepopPageOne />;
}
