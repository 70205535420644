'use client';

import {
  ApiCalls,
  EventTypes,
  UserEvents,
  useLogEvent,
} from '@/app/hooks/useLogEvent';
import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useQuery } from '@tanstack/react-query';
import { api } from '@/app/network/api';
import { isAxiosError } from 'axios';

const fetchCheckMIDExists = async ({
  partner,
  merchantID,
  successCallback,
  traceID,
  spanID,
  logUserEvent,
  logAPICall,
  logError,
}: {
  partner: string;
  merchantID: string;
  successCallback: (data: any) => void;
  traceID: string;
  spanID: string;
  logUserEvent: (eventName: UserEvents, value?: string) => void;
  logAPICall: (requestName: string, traceId: string, spanId: string) => void;
  logError: (
    error: Error,
    traceId?: string,
    spanId?: string,
    metadata?: object,
  ) => void;
}) => {
  if (!merchantID) {
    successCallback(false);
    return false;
  }

  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');

  let response;

  try {
    const response = await api.get(
      `/api/${partner}/validate/mid?partner=${partner}&mid=${merchantID}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          'X-Datadog-Trace-Id': traceID,
          'X-Datadog-Parent-Id': spanID,
          'X-Datadog-Origin': 'local',
          'X-Datadog-Sampling-Priority': '1',
        },
      },
    );
    logAPICall(ApiCalls.VALIDATE_MID, traceID, spanID);

    const isMIDValid = response?.status === 200;

    successCallback(isMIDValid);

    if (!isMIDValid) {
      logUserEvent(UserEvents.MERCHANT_ENTERED_INVALID_MID);
    }

    return isMIDValid;
  } catch (error) {
    if (isAxiosError(error)) {
      const { response, request } = error;
      if (response) {
        switch (response.status) {
          case 404: {
            successCallback(false);
            logUserEvent(UserEvents.NO_PREPOP_DATA);
            return false;
          }
          default: {
            logError(
              new Error('Unexpected mid check response', {
                cause: error,
              }),
            );
            return null;
          }
        }
      } else if (request) {
        if (request?.statusText === 'abort') {
          return null;
        } else {
          logError(new Error(request.statusText));
        }
        return null;
      } else {
        logError(new Error(error.message));
        return null;
      }
    } else if (error instanceof Error) {
      logError(error);
      return null;
    } else {
      logError(new Error('Unknown error'));
      return null;
    }
  }
};

export const useCheckMIDExists = (
  partner: string,
  sellerKey: string,
  successCallback: (data: any) => void,
) => {
  const logAPICall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const traceID = typeof window !== 'undefined' ? generateUniqueId() : '';
  const spanID = typeof window !== 'undefined' ? generateUniqueId() : '';

  const fetchCheckMIDExistsParams: {
    partner: string;
    merchantID: string;
    successCallback: (data: any) => void;
    traceID: string;
    spanID: string;
    logUserEvent: (eventName: UserEvents, value?: string) => void;
    logAPICall: (requestName: string, traceId: string, spanId: string) => void;
    logError: (
      error: Error,
      traceId?: string,
      spanId?: string,
      metadata?: object,
    ) => void;
  } = {
    partner,
    merchantID: sellerKey,
    successCallback,
    traceID,
    spanID,
    logUserEvent,
    logAPICall,
    logError,
  };

  const query = useQuery({
    queryKey: [`Validate MID ${sellerKey}`],
    queryFn: () => fetchCheckMIDExists(fetchCheckMIDExistsParams),
    retry: (failureCount) => {
      if (failureCount < 3) {
        return true;
      } else {
        logError(new Error(`MID validity check not ok`), traceID, spanID);
        return false;
      }
    },
  });

  return { ...query };
};
