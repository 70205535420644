'use client';

import { RadioInput } from '@/v2Components/RadioInput';
import { ParsedText } from '@/app/components/ParsedText';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';

const BasicConsent = () => {
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);

  return (
    <p
      className={`text-core-7 mb-8 text-body-4-light text-pretty`}
      data-testid="consent-box"
    >
      <ParsedText
        htmlString={t('pp-bd1-liberis-consent')
          .replace(
            '{terms_and_conditions}',
            partnerSettings['terms-and-conditions-url'] ?? '',
          )
          .replace(
            '{privacy_policy}',
            partnerSettings['privacy-policy-url'] ?? '',
          )}
      />
    </p>
  );
};

const ExplicitConsent = ({
  handleChange,
  checkedValue,
}: {
  handleChange?: any;
  checkedValue?: boolean | null;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-body-4-light mb-k-7">
        {t('pp-explicit-marketing-consent')}
      </p>
      <div className="mb-3">
        <RadioInput
          name="explicitMarketingConsent"
          value="true"
          handleChange={handleChange}
          id="YesConsent"
          label={t('pp-marketing-consent-yes-label')}
          required
          checked={checkedValue === true}
        />
      </div>
      <div>
        <RadioInput
          name="explicitMarketingConsent"
          value="false"
          handleChange={handleChange}
          id="NoConsent"
          label={t('pp-marketing-consent-no-label')}
          required
          errorMessage={t('pp-explicit-marketing-consent-error')}
          checked={checkedValue === false}
        />
      </div>
    </>
  );
};

const Consent = ({
  handleChange,
  checkedValue,
}: {
  handleChange?: any;
  checkedValue?: boolean | null;
}) => {
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);

  if (partnerSettings.consentType === 'checkForExplicitMarketingConsent') {
    return (
      <ExplicitConsent
        handleChange={handleChange}
        checkedValue={checkedValue}
      />
    );
  } else {
    return <BasicConsent />;
  }
};

export default Consent;
