import axios from 'axios';

// Using this as a placeholder so we can easily add to
// the axios instance if we need to add headers, etc.
// without having to refactor a load of uses.
// Right now it's just a passthrough.

const axiosInstance = axios.create();

export { axiosInstance as api };
