import { ParsedText } from '@/app/components/ParsedText';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { Button } from '@/v2Components/Button/Button';
import Link from 'next/link';
import { useParams } from 'next/navigation';

const ApplicationDisclosure = ({
  hideDisclosure,
}: {
  hideDisclosure: () => void;
}) => {
  const params: { partner: string } = useParams();
  const { t } = useTranslation();
  const partnerSettings = getPartnerSettings(params.partner);

  return (
    <>
      <h2 className="text-body-2 text-core-8 mb-k-4">
        {t('pp-application-disclosure-heading')}
      </h2>
      <div className="text-body-4 text-core-7 ">
        <ParsedText
          htmlString={t('pp-application-disclosure-content', {
            'privacy-policy-url': partnerSettings['privacy-policy-url'] || '',
          })}
        />
      </div>
      <div className="mt-k-12 text-center">
        <Button
          mixpanelButtonText="applicationDisclosureContinue"
          id="applicationDisclosureContinue"
          onClick={hideDisclosure}
        >
          {t('pp-application-disclosure-continue')}
        </Button>
        <Link
          href="/"
          className="inline-block relative mx-auto mt-k-8 text-body-3 focus:underline hover:underline text-core-8"
        >
          {t('pp-application-disclosure-go-back')}
        </Link>
      </div>
    </>
  );
};

export { ApplicationDisclosure };
